import { createRoot } from 'react-dom/client';
import { App } from './App';

const container = document.getElementById('eloquent-trigger') as HTMLElement;

if (container) {
  const root = createRoot(container);
  try {
    root.render(<App />);
  } catch (error) {
    console.error(error);
  }
}
